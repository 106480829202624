<template>
  <div class="" uk-sticky="offset:80">

  </div>
</template>

<script>
export default {
  name: "Right",
  components: {}
}
</script>
