<template>
  <div class="lg:flex lg:space-x-10">
    <div class="lg:w-3/5">
      <div class="card">
        <div class="p-4">
          <h1 class="lg:text-3xl text-2xl font-semibold mb-6">{{ page.title }}</h1>
          <div class="space-y-3" v-html="page.content"></div>
        </div>
      </div>

      <div class="mt-4" v-if="page.show_comment_box">
        <comment object-type="page" :comment-id="commentId" :object-id="page.id" :slug="slug" field="slug" type="String"/>
      </div>
    </div>
    <div class="lg:w-2/5 w-full">
      <div class="card p-3">
        <latest-comment/>
      </div>
      <right/>
    </div>
  </div>
</template>

<script>
import ApiService from "../../core/services/api.service";
import {changePageTitle, logActivity, numberFormat, timeago} from "../../core/services/utils.service";
import Comment from "../comment/Comment";
import LatestComment from "../comment/LatestComment";
import Right from "../right/Right";

export default {
  name: "PageDetail",
  components: {
    Right,
    LatestComment,
    Comment
  },
  data() {
    return {
      slug: null,
      commentId: null,
      page: {
        id: null,
        title: "",
        content: "",
        show_comment_box: 1,
        created_at: ""
      },
      otherPages: []
    }
  },
  methods: {
    loadPage() {
      let query = `query($slug: String!) {
        page(slug: $slug) {
          id
          title
          slug
          content
          show_comment_box
          created_at
        }
      }
      `;

      ApiService.graphql(query, {slug: this.slug})
          .then(({data}) => {
            if (data.data && data.data.page) {
              this.page = data.data.page;
              logActivity("view", "page", this.page.id);
              changePageTitle(this.page.title);
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    reRender() {
      this.loadPage();
    },
    timeago(x) {
      return timeago(x);
    },
    numberFormat(x) {
      return numberFormat(x);
    },
  },
  mounted() {
    this.slug = this.$route.params.slug;
    this.commentId = this.$route.query.comment_id;
    this.reRender();
  },
  beforeRouteUpdate(to, from, next) {
    this.slug = to.params.slug;
    this.commentId = this.$route.query.comment_id;
    this.reRender();
    window.scrollTo(0, 0);
    next();
  }
}
</script>
